<template>
  <div>
    <div class="flex h c" style="margin-bottom: 12px;">
      <el-button type="primary" icon="el-icon-arrow-left" @click="handleBack">返回</el-button>
      <div class="padding-0-20">
        <div class="fc-g" style="font-size: 12px;">自定义客户限量</div>
      </div>
    </div>
    <!--模板下载 导入  -->
    <div class="head-container" v-if="isNoView">
      <el-upload class="filter-item" ref="upload" :action="uploadUrl" :headers="uploadHeaders" :before-upload="handleBeforeUpload" :on-success="handleUploadSuccess" :on-error="handleUploadError" :show-file-list="false" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
        <el-button type="primary" class="filter-item" icon="el-icon-upload2" :loading="uploading">{{btnName}}</el-button>
      </el-upload>
      <el-button type="primary" @click="downloadTemplate" class="filter-item" icon="el-icon-download" :loading="downloadLoading">模板下载</el-button>
      <el-button class="filter-item" type="primary" @click="handleDoSave" :disabled="!successList.length>0">保存自定义客户限量</el-button>

      <div class="padding-10-0">
        <div class="fc-g" style="font-size: 12px;">
          <span>导入结果：共{{successList.length+errList.length}}条,成功{{successList.length}}条,失败{{errList.length}}条</span>
        </div>
      </div>
    </div>

    <local-table ref="successListDetail" :store="successList" border :page-size="10" page-layout="total, prev, pager, next" page-style="padding: 0.25rem 1rem">
      <el-table-column prop="erpCode" min-width="200" show-overflow-tooltip label="客户编码" />
      <el-table-column prop="name" min-width="200" show-overflow-tooltip label="客户名称" />
      <el-table-column prop="quantity" min-width="200" show-overflow-tooltip label="限量" />
    </local-table>
    <div class="v" v-if="errList && errList.length>0">
      <div class="padding-10-0">错误详情</div>
      <local-table ref="errListDetail" :store="errList" border :page-size="5" page-layout="total, prev, pager, next" page-style="padding: 0.25rem 1rem">
        <el-table-column prop="erpCode" min-width="200" show-overflow-tooltip label="客户编码" />
        <el-table-column prop="name" min-width="200" show-overflow-tooltip label="客户名称" />
        <el-table-column prop="quantity" min-width="200" show-overflow-tooltip label="限量" />
      </local-table>
    </div>
  </div>
</template>

<script>
import config from "@/config";
import { getToken } from "@/utils/auth";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  props: {
    successList: Array,
    isNoView: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      uploadHeaders: {
        Authorization: "Bearer " + getToken(),
      },
      downloadLoading: false,
      uploading: false,
      errList: [],
      successList: [],
      errInfo: null,
    };
  },
  computed: {
    btnName() {
      return this.uploading ? "导入中" : "选择文件";
    },
    uploadUrl() {
      return config.hosts.request + "api/groupSales/customer/limit/import";
    },
  },
  methods: {
    handleBack() {
      this.$emit("change");
    },
    handleBeforeUpload() {
      this.uploading = true;
    },
    handleUploadSuccess(res) {
      this.successList = res.success;
      this.errList = res.error;
      this.uploading = false;
    },
    handleUploadError(err) {
      this.errInfo = err;
      this.uploading = false;
    },
    // 模板下载
    downloadTemplate() {
      this.downloadLoading = true;
      download("api/groupSales/customer/limit/template")
        .then((result) => {
          downloadFile(result, "自定义客户限量模板", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    handleDoSave() {
      this.$emit("submit", this.successList);
    },
  },
};
</script>

<style>
</style>